const GET_ALL_USERS = "All users got";
const GET_RANKED_TIG = "Most tiged users got";
const MAKE_ADMIN = "User status modified";
const GET_USER = "Get a user";
const CREATE_TIG = "New Tig created";
const ASSIGN_TASK = "Task assigned";
const SEARCH_USER = "Search a user";
const CLOSE_TIG = "Tig closed";
const CANCEL_TIG = "Tig canceled";
const TO_STAFF = "User to staff";
const TIG_BY_STATUS = "Tig by status";
const TIG_BY_USER = "Tig by user";
export {
  GET_ALL_USERS,
  GET_USER,
  MAKE_ADMIN,
  CREATE_TIG,
  ASSIGN_TASK,
  SEARCH_USER,
  CLOSE_TIG,
  CANCEL_TIG,
  TO_STAFF,
  GET_RANKED_TIG,
  TIG_BY_STATUS,
  TIG_BY_USER,
};
