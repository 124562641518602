import {
  GET_USER_TIGS,
  GET_AUTHOR_TIGS
} from "../constants/userConstants";

import UserService from "../../services/users-services";

import store from "..";

export const getUserTigs = (mail, status) => (dispatch) => {
  return UserService.userTigs(mail, status)
    .then((data) => {
      store.dispatch({
        type: GET_USER_TIGS,
        payload: {
          tigs: data.userTigs,
        },
      });

      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
};

export const getAuthorTigs = (mail, status) => (dispatch) => {
  return UserService.authorTigs(mail, status)
    .then((data) => {
      store.dispatch({
        type: GET_AUTHOR_TIGS,
        payload: {
          tigs: data.userTigs,
        },
      });

      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
};