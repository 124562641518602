import {
  GET_ALL_USERS,
  GET_USER,
  SEARCH_USER,
  MAKE_ADMIN,
  CREATE_TIG,
  ASSIGN_TASK,
  CLOSE_TIG,
  CANCEL_TIG,
  TO_STAFF,
  GET_RANKED_TIG,
  TIG_BY_STATUS,
  TIG_BY_USER,
} from "../constants/adminConstants";

import AdminService from "../../services/admin-services";

import store from "..";

export const getRankTig = () => (dispatch) => {
  return AdminService.getRankedTig()
    .then((data) => {
      store.dispatch({
        type: GET_RANKED_TIG,
        payload: {
          result: data,
        },
      });
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
};

export const getAllUsers = (q, page, perPage, order) => (dispatch) => {
  return AdminService.getAllUsers(q, page, perPage, order)
    .then((data) => {
      store.dispatch({
        type: GET_ALL_USERS,
        payload: {
          users: data.users,
          currentRows: data.currentRows,
          totalRows: data.totalRows,
        },
      });

      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
};

export const getUser = (mail) => (dispatch) => {
  return AdminService.getUser(mail)
    .then((data) => {
      store.dispatch({
        type: GET_USER,
        payload: {
          user: data,
        },
      });

      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
};

export const createTig =
  (reason, gravity, user_mail, created_by, updated_by) => (dispatch) => {
    return AdminService.createTig(
      reason,
      gravity,
      user_mail,
      created_by,
      updated_by
    )
      .then((data) => {
        store.dispatch({
          type: CREATE_TIG,
          payload: {
            tig: data,
          },
        });

        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject();
      });
  };

export const assignTask = (tig_id, task, due_date) => (dispatch) => {
  return AdminService.assignTask(tig_id, task, due_date)
    .then((data) => {
      store.dispatch({
        type: ASSIGN_TASK,
        payload: {
          tigUp: data,
        },
      });

      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
};

export const makeAdmin = (token, id) => (dispatch) => {
  return AdminService.makeAdmin(token, id)
    .then((data) => {
      store.dispatch({
        type: MAKE_ADMIN,
        payload: { user: data },
      });

      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
};

export const searchUser = (key) => (dispatch) => {
  return AdminService.searchUser(key)
    .then((data) => {
      store.dispatch({
        type: SEARCH_USER,
        payload: { users: data },
      });

      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
};

export const closeTig = (id) => (dispatch) => {
  return AdminService.closeTig(id)
    .then((data) => {
      store.dispatch({
        type: CLOSE_TIG,
        payload: { tig: data },
      });

      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
};

export const cancelTig = (id) => (dispatch) => {
  return AdminService.cancelTig(id)
    .then((data) => {
      store.dispatch({
        type: CANCEL_TIG,
        payload: { tig: data },
      });

      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
};

export const toStaff = (id, role) => (dispatch) => {
  return AdminService.toStaff(id, role)
    .then((data) => {
      store.dispatch({
        type: TO_STAFF,
        payload: { user: data },
      });

      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
};

export const tByStatus = (status) => (dispatch) => {
  return AdminService.tigsByStatus(status)
    .then((data) => {
      store.dispatch({
        type: TIG_BY_STATUS,
        payload: { tigs: data },
      });

      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
};

export const tByUser = (mail) => (dispatch) => {
  return AdminService.userTigs(mail)
    .then((data) => {
      store.dispatch({
        type: TIG_BY_USER,
        payload: { tigs: data },
      });

      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject();
    });
};
