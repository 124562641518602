import {
    REGISTER_SUCCESS,
    GET_PROFILE,
} from '../constants/authConstants';

import AuthService from '../../services/auth-header';
import UsersService from '../../services/users-services';

import store from "../../store";


export const register = (name, email) => (dispatch) => {
    return AuthService.register(name, email).then(
        (response) =>{
            store.dispatch({
                type: REGISTER_SUCCESS,
                payload: {user : response},
            });
            return Promise.resolve();
        }
    )
    .catch((error) => {
        return Promise.reject();
    });
};

export const profil = () => (dispatch) => {
    return UsersService.profil().then(
        (data) => {
            store.dispatch({
                type: GET_PROFILE,
                payload: { user : data },
            });
    
            return Promise.resolve();
        }
    )
    .catch((error) => {
        return Promise.reject();
    });
};
