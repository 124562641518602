/* eslint-disable import/no-anonymous-default-export */
import {
  GET_ALL_USERS,
  GET_USER,
  SEARCH_USER,
  MAKE_ADMIN,
  CREATE_TIG,
  ASSIGN_TASK,
  CLOSE_TIG,
  CANCEL_TIG,
  TO_STAFF,
  GET_RANKED_TIG,
  TIG_BY_STATUS,
  TIG_BY_USER,
} from "../constants/adminConstants";

const initialState = {
  users: [],
  totalRows: 0,
  user: {},
  message: "",
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_USERS:
      return {
        ...state,
        users: payload.users,
        totalRows: payload.totalRows,
      };
    case GET_RANKED_TIG:
      return {
        ...state,
        mostTig: payload.result,
      };
    case GET_USER:
      return {
        ...state,
        otherUser: payload.user,
      };
    case MAKE_ADMIN:
      return {
        ...state,
        user: payload.user,
        message: payload.user.name + " right have been edited",
      };
    case CREATE_TIG:
      return {
        ...state,
        tig: payload.tig,
        message: payload.tig._id + " right have been edited",
      };

    case ASSIGN_TASK:
      return {
        ...state,
        tigUp: payload.tigUp,
        message: payload.tigUp._id + " right have been updated",
      };

    case SEARCH_USER:
      return {
        ...state,
        users: payload.users,
      };

    case CLOSE_TIG:
      return {
        ...state,
        tig: payload.tig,
      };

    case CANCEL_TIG:
      return {
        ...state,
        tig: payload.tig,
      };

    case TO_STAFF:
      return {
        ...state,
        user: payload.user,
      };

    case TIG_BY_STATUS:
      return {
        ...state,
        tigs: payload.tigs.Tigs, 
      };

    case TIG_BY_USER:
      return {
        ...state,
        tigs: payload.tigs.userTigs,
      };

    default:
      return state;
  }
}
