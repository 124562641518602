export const msalConfig = {
  auth: {
    clientId: "d531a837-f0d2-4e0f-be7b-6dd532329f4d",
    authority:
      "https://login.microsoftonline.com/901cb4ca-b862-4029-9306-e5cd0f6d9f86", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: "https://tig.epitools.bj/",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};