import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { StaffTig } from "pages/staff/staffTig";
import { UsersLists } from "pages/admin/usersLists";
import { Dashboard } from "pages/admin/dashboard";

import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/auth";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<App />}></Route>
            <Route path="/tigs" element={<StaffTig />} />
            <Route path="/users" element={<UsersLists />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="*" element={<App />} />
          </Routes>
        </Router>
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
