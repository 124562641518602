import React, { useEffect, useState, useRef } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from "recharts";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import {
  getUser,
  getRankTig,
  tByStatus,
} from "../../store/actions/adminUsersActions";
import store from "../../store";
import { Header } from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, Chip, Container } from "@mui/material";
import { Footer } from "../../components/Footer";
import useAuthentication from "hooks/useAuthentication";

const Dashboard = () => {
  const [status, setStatus] = useState("closed");
  const [e, setE] = useState("");
  // const [role, setRole] = useState("");
  const [tigs, setTigs] = useState([]);
  const [mostTig, setMostTig] = useState([]);
  const [users, setUsers] = useState([]);
  // const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  // const mail = accounts[0] && accounts[0].username;
  const navigate = useNavigate();
  const mostTigArray = mostTig.filter((item) => item._id !== "");
  mostTigArray.sort((a, b) => {
    if (a.count === b.count) {
      return a._id > b._id ? 1 : -1;
    }
    return b.count - a.count;
  });

  const { isAuthenticated, role, name, mail } = useAuthentication();


  // useEffect(() => {
  //   let isSubscribed = true;
  //   if (isAuthenticated) {
  //     getTigs().then(() => {
  //       dispatch(getUser(mail))
  //         .then(() => {
  //           if (store.getState().admin.otherUser.type !== "admin") {
  //             navigate("/");
  //           }
  //           setRole(store.getState().admin.otherUser.type);
  //         })
  //         .catch(() => {
  //           navigate("/");
  //         });
  //     });
  //     getMostTig();
  //   } else {
  //     navigate("/");
  //   }
  //   return () => (isSubscribed = false);
  // }, [isAuthenticated, mostTig, status, tigs, users, dispatch, mail]);


  useEffect(() => {

    if (!isAuthenticated) {
      navigate("/");
    } else if (isAuthenticated && role === "admin") {
      getTigs()
      getMostTig()
    }
  }, [isAuthenticated, navigate, role]);

  async function getTigs() {
    if (mail && status) {
      try {
        await dispatch(tByStatus(status)).then(() => {
          setTigs(store.getState().admin.tigs);
        });
      } catch (e) {
        setE(e);
      }
    }
  }

  async function getMostTig() {
    try {
      await dispatch(getRankTig()).then(() => {
        setMostTig(store.getState().admin.mostTig);
      });
      //console.log(mostTigArray);
    } catch (e) {
      setE(e);
    }
  }
  return (
    <div>
      <Header />
      <Container>
        <div className="flex flex-wrap justify-center gap-10 mt-8">
          <Card>
            <CardContent style={{ padding: 0 }}>
              <div className="p-5 mb-2 bg-blue-700 text-white">
                <div className="font-bold font-xs">
                  Nombres de tâches exécutées
                </div>
                <div className="font-bold text-2xl">{tigs.length}</div>
              </div>
              <Container>
                <ul className="mb-2">
                  <li>
                    <Chip
                      style={{
                        background: "red",
                        color: "white",
                      }}
                      label="HIGH"
                    />
                    <span className="text-black font-bold ml-2">
                      {tigs.filter((tig) => tig.gravity === "high").length}
                    </span>
                  </li>
                  <li className="mt-2">
                    <Chip
                      style={{
                        background: "orange",
                        color: "white",
                      }}
                      label="MED"
                    />
                    <span className="text-black font-bold ml-2">
                      {tigs.filter((tig) => tig.gravity === "medium").length}
                    </span>
                  </li>
                  <li className="mt-2">
                    <Chip
                      style={{
                        background: "green",
                        color: "white",
                      }}
                      label="LOW"
                    />
                    <span className="text-black font-bold ml-2">
                      {tigs.filter((tig) => tig.gravity === "low").length}
                    </span>
                  </li>
                </ul>
              </Container>
            </CardContent>
          </Card>

          {/*
          <Card>
            <CardContent>
              <BarChart
                width={1000}
                height={200}
                data={mostTigArray}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <Bar dataKey="count" fill="#1D4ED8" />
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="_id" />
                <YAxis />
              </BarChart>
              <div className="font-bold font-xs">
                Les étudiants les plus tigés
              </div>
            </CardContent>
          </Card>
*/}
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export { Dashboard };
