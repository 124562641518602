/* eslint-disable import/no-anonymous-default-export */
import {
    REGISTER_SUCCESS,
    LOGOUT,
    GET_PROFILE,
} from '../constants/authConstants';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user
   ? { isLoggedIn: true, user, message : null }
   : { isLoggedIn: false, user: null, message : null};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
                user: payload.user,
                message : null,
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                message : null,
            }; 
        case GET_PROFILE:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                message : null,
            };                          
        default:
            return state;
    }
}