import { useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { getUser } from "../store/actions/adminUsersActions";
import { register } from "../store/actions/authActions";
import store from "../store";

const useAuthentication = () => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const name = accounts[0] && accounts[0].name;
  const mail = accounts[0] && accounts[0].username;
  const [role, setRole] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUser(mail))
        .then(() => {
          setRole(store.getState().admin.otherUser.type);
        })
        .catch(() => {
          dispatch(register(name, mail))
            .then(() => {})
            .catch(() => {});
        });
    }
  }, [isAuthenticated, dispatch, name, mail, role]);

  return { isAuthenticated, role, name, mail, dispatch };
};

export default useAuthentication;
