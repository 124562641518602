import axios from "axios";
import { urlConstant } from '../store/constants/urlConstant';
// import "dotenv";
import store from "store";


const API_URL = urlConstant.url;

const profil = () => {
  return axios
    .get(API_URL + "/users/me", 
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          'Role':store.getState().admin && store.getState().admin.otherUser && store.getState().admin.otherUser.type
        }
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const userTigs = (mail, status) => {
  return axios
    .get(API_URL + "/tigs/user/" + mail + "/status/" + status,
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          'Role':store.getState().admin && store.getState().admin.otherUser && store.getState().admin.otherUser.type
        }
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const authorTigs = (mail, status) => {
  return axios
    .get(API_URL + "/tigs/author/" + mail + "/status/" + status,
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          'Role':store.getState().admin && store.getState().admin.otherUser && store.getState().admin.otherUser.type
        }
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  profil,
  userTigs,
  authorTigs,
};
