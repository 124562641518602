import { combineReducers } from 'redux';
import authReducer from './authReducer';
import adminUsersReducer from './adminUsersReducer';
import userReducer from './userReducer';


const rootReducer = combineReducers({
    auth : authReducer,
    admin : adminUsersReducer,
    user : userReducer,
   })

export default rootReducer;
// export default authReducer;