import axios from 'axios';
import { urlConstant } from '../store/constants/urlConstant';
import store from "store";

const API_URL = urlConstant.url + "/users/create";

const register = (name, email) => {
    return axios.post(API_URL, {
        name,
        email,
    }).then((response) => {
        return response.data
    }).catch((err)=>{
        throw err.response
    });
};


// eslint-disable-next-line import/no-anonymous-default-export
export default {
    register
};