import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/auth";
import { Button, Container } from "@mui/material";

async function handleLogin(instance) {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
  const { instance } = useMsal();

  return (
    <Container className="rounded padding">
      <Button
        variant="contained"
        onClick={() => {
          handleLogin(instance).then((r) => console.log(r));
        }}
      >
        <div className="flex space-x-4">
          <div className="m-auto">
            <img
              alt="microsoft office"
              src="/images/microsoft.png"
              height="50"
              width="50"
            />
          </div>
          <div className="m-auto">
            <p>Connectez-vous avec Microsoft</p>
          </div>
        </div>
      </Button>
    </Container>
  );
};
