import axios from "axios";
import { urlConstant } from '../store/constants/urlConstant';
// import "dotenv";
import store from "store";

const API_URL = urlConstant.url;

const getAllUsers = async (q, page, perPage, order) => {
  return axios
    .get(
      API_URL +
      "/users?q=" +
      q +
      "&page=" +
      page +
      "&perPage=" +
      perPage +
      "&order=" +
      order
      ,
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          'Role':store.getState().admin && store.getState().admin.otherUser && store.getState().admin.otherUser.type
        }
      })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const getRankedTig = () => {
  return axios
    .get(API_URL + "/tigs/rankTig",
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          'Role': store.getState().admin && store.getState().admin.otherUser && store.getState().admin.otherUser.type
        }
      }
    )
    .then((response) => {
      //console.log("response", response.data.rankedTigs);
      return response.data.rankedTigs;
    })
    .catch((err) => {
      throw err.response;
    });
};

const getUser = (mail) => {
  return axios
    .get(API_URL + "/users/profil/" + mail,
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          'Role': store.getState().admin && store.getState().admin.otherUser && store.getState().admin.otherUser.type
        }
      }
    )
    .then((response) => {
      // console.log(response.data)
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const searchUser = (key) => {
  return axios
    .get(API_URL + "/users/search/" + key,
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          'Role': store.getState().admin && store.getState().admin.otherUser && store.getState().admin.otherUser.type
        }
      })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const createTig = (reason, gravity, user_mail, created_by, updated_by) => {
  return axios
    .post(API_URL + "/tigs/create", {
      reason,
      gravity,
      user_mail,
      created_by,
      updated_by,
    },
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          'Role': store.getState().admin && store.getState().admin.otherUser && store.getState().admin.otherUser.type
        }
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const assignTask = (tig_id, task, due_date) => {
  return axios
    .post(API_URL + "/tigs/tasked/" + tig_id, {
      task,
      due_date,
    },
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          'Role':store.getState().admin && store.getState().admin.otherUser && store.getState().admin.otherUser.type
        }
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const closeTig = (tig_id) => {
  return axios
    .post(API_URL + "/tigs/close/" + tig_id, {},
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          'Role':store.getState().admin && store.getState().admin.otherUser && store.getState().admin.otherUser.type
        }
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const cancelTig = (tig_id) => {
  return axios
    .post(API_URL + "/tigs/cancel/" + tig_id, {},
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          'Role':store.getState().admin && store.getState().admin.otherUser && store.getState().admin.otherUser.type
        }
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const toStaff = (id, role) => {
  const headers = {
    'Role': role,
  }
  // console.log('id', id)
  return axios
    .post(API_URL + "/users/edit/rights/" + id, {},
      {
        headers
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const tigsByStatus = (status) => {
  return axios
    .get(API_URL + "/tigs/status/" + status,
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          'Role':store.getState().admin && store.getState().admin.otherUser && store.getState().admin.otherUser.type
        }
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
}

const userTigs = (mail) => {
  return axios
    .get(API_URL + "/tigs/user/" + mail,
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          'Role':store.getState().admin && store.getState().admin.otherUser && store.getState().admin.otherUser.type
        }
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllUsers,
  getUser,
  searchUser,
  createTig,
  assignTask,
  closeTig,
  cancelTig,
  toStaff,
  getRankedTig,
  tigsByStatus,
  userTigs,
};
