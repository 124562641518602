import { React, useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import store from "../../store";
import {
  getAllUsers,
  getUser,
  toStaff,
  searchUser,
} from "../../store/actions/adminUsersActions";
import { getUserTigs } from "../../store/actions/userActions";
import { sendNotify } from "hooks/notify/notify";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  IconButton,
} from "@mui/material";
import useAuthentication from "hooks/useAuthentication";
import axios from "axios";
import { urlConstant } from "store/constants/urlConstant";
import { tr } from "faker/lib/locales";


const initialState = {
  // query: "",
  users: [],
  user: null,
  // perPage: 5,
  // page: 1,
  // totalRows: 0,
  order: "asc",
  tig_order: "random",
};

function downloadCSVFile(csv_data) {

  // Create CSV file object and feed
  // our csv_data into it
  let CSVFile = new Blob([csv_data], {
    type: "text/csv"
  });

  // Create to temporary link to initiate
  // download process
  var temp_link = document.createElement('a');

  // Download csv file
  temp_link.download = "recapitulatif.csv";
  var url = window.URL.createObjectURL(CSVFile);
  temp_link.href = url;

  // This link should not be displayed
  temp_link.style.display = "none";
  document.body.appendChild(temp_link);

  // Automatically click the link to
  // trigger download
  temp_link.click();
  document.body.removeChild(temp_link);
}

function tableToCSV() {
  // Variable to store the final csv data
  var csv_data = [];

  // Get each row data
  var rows = document.getElementsByTagName('tr');
  console.log(rows);
  for (var i = 0; i < rows.length; i++) {

    // Get each column data
    var cols = rows[i].querySelectorAll('#tdd, #thh');

    // Stores each csv row data
    var csvrow = [];
    for (var j = 0; j < cols.length; j++) {

      // Get the text data of each cell
      // of a row and push it to csvrow
      csvrow.push(cols[j].innerHTML);
    }

    // Combine each column value with comma
    csv_data.push(csvrow.join(","));
  }
  // Combine each row data with new line character
  csv_data = csv_data.join('\n');

  // Call this function to download csv file
  downloadCSVFile(csv_data);
}

function UsersLists() {
  const navigate = useNavigate();
  let [state, setState] = useState(initialState);
  // const [role, setRole] = useState("");
  const [pages, setPages] = useState(0);
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const { accounts, instance } = useMsal();
  const mail = accounts[0] && accounts[0].username;


  // const { isAuthenticated, role, name, mail } = useAuthentication();
  const API_URL = urlConstant.url;
  const role = store.getState().admin.otherUser.type;

  const getAllUsersWithTigs = async () => {
    try {
      const response = await axios.get(`${API_URL}/users/usersTigs`, { headers: {
        // Authorization: `Bearer ${token}`,
        'Role': role
      }})
      setState({
        ...state,
        users: response.data.data,
      });
      // console.log('resp',response.data.data)
    } catch (error) {
      console.log(error)
    }
  }


  // useEffect(() => {
  //   if (isAuthenticated) {
  //     dispatch(getUser(mail))
  //       .then(() => {
  //         if (store.getState().admin.otherUser.type !== "admin") {
  //           navigate("/");
  //         }
  //         setRole(store.getState().admin.otherUser.type);
  //       })
  //       .catch(() => {
  //         navigate("/");
  //       });
  //     getUsers();
  //   } else {
  //     navigate("/");
  //   }
  // }, [isAuthenticated, mail]);

  useEffect(() => {
    if (store.getState().admin && store.getState().admin.otherUser && store.getState().admin.otherUser.type) {
      //   navigate("/");
      // } else {
      // getUsers()

        // if (!response.ok) {
        //   throw new Error('Failed to fetch user data');
        // }

      // test()

      getAllUsersWithTigs()
    }


  }, [isAuthenticated, navigate]);

  async function getUsers(
    q = state.query,
    page = state.page,
    perPage = state.perPage,
    order = state.order
  ) {
    const Users = [];
    await dispatch(getAllUsers(q, page, perPage, order))
      .then(() => {
        const users = store.getState().admin.users;
        users.forEach((user) => {
          dispatch(getUserTigs(user.email, "closed")).then(() => {
            const tigT = store.getState().user.tigs.length;
            dispatch(getUserTigs(user.email, "pending")).then(() => {
              const tigP = store.getState().user.tigs.length;
              dispatch(getUserTigs(user.email, "in progress")).then(() => {
                const tigC = store.getState().user.tigs.length;
                Users.push({
                  ...user,
                  tt: tigT,
                  tea: tigP,
                  tec: tigC,
                });
                setState({
                  ...state,
                  users: Users,
                  // totalRows: store.getState().admin.totalRows,
                });
              });
            });
          });
        })

        // nbrPage(perPage, store.getState().admin.totalRows);
        // setState({
        //   ...state,
        //   // users: users,
        //   query: q,
        //   page: page,
        //   perPage: perPage,
        //   order: order,
        //   totalRows: store.getState().admin.totalRows,
        // });
      })
      .catch(() => {
        sendNotify("error", "Users error !", store.getState().admin.message);
      });
  }

  async function staff(id) {
    let confirmation = window.confirm(
      "Do you really want to change this user rights ?"
    );
    if (confirmation) {
      // console.log(role)
      dispatch(toStaff(id, role))
        .then(() => {
          // getUsers();
          getAllUsersWithTigs()
          sendNotify(
            "success",
            "user right edited !",
            '<span style="font-weight : bold;">' +
            store.getState().admin.user.name +
            '</span> account is now <span style="font-weight : bold;">' +
            store.getState().admin.user.type +
            "</span>."
          );
        })
        .catch(() => {
          sendNotify(
            "error",
            "User admin rigths error !",
            store.getState().admin.message
          );
        });
    }
  }

  //  On change d'un input actualiser le state local
  const handleQueryChange = (e) => {
    if (e.target.value === "") {
      // getUsers();
      getAllUsersWithTigs()
    }
    else {
      const Users = [];
      dispatch(searchUser(e.target.value)).then(() => {
        const users = store.getState().admin.users.users;
        users.forEach((user) => {
          dispatch(getUserTigs(user.email, "closed")).then(() => {
            const tigT = store.getState().user.tigs.length;
            dispatch(getUserTigs(user.email, "pending")).then(() => {
              const tigP = store.getState().user.tigs.length;
              dispatch(getUserTigs(user.email, "in progress")).then(() => {
                const tigC = store.getState().user.tigs.length;
                Users.push({
                  ...user,
                  tt: tigT,
                  tea: tigP,
                  tec: tigC,
                });
                setState({
                  ...state,
                  users: Users,
                  // totalRows: store.getState().admin.totalRows,
                });
              });
            });
          });
        })
      });
    }
  };

  const newHandleQueryChange = async (e) => {
    if (e.target.value === "") {
      getAllUsersWithTigs()
    } else {
      try {
        const response = await axios.get(`${API_URL}/users/usersTigs/search/${e.target.value}`, 
          { headers: {
          'Role': store.getState().admin.otherUser.type
        }})
        setState({
          ...state,
          users: response.data.users,
        });
        // console.log('respi',response.data.users)
      } catch (error) {
        console.log(error)
      }
    }
  }


  async function sortUp() {
    setState({
      ...state,
      users: state.users.sort((a, b) => {
        return a.name.localeCompare(b.name);
      }),
      order: "asc",
      tigs_order: "random",
    });
  }

  async function sortDown() {
    setState({
      ...state,
      users: state.users.sort((a, b) => {
        return b.name.localeCompare(a.name);
      }),
      order: "desc",
      tigs_order: "random",
    });
  }

  function sortTigTUp() {
    setState({
      ...state,
      users: state.users.sort((a, b) => a.tt - b.tt),
      tig_order: "asc",
    });
  }

  function sortTigTDown() {
    setState({
      ...state,
      users: state.users.sort((a, b) => b.tt - a.tt),
      tig_order: "desc",
    });
  }

  function sortTigPUp() {
    setState({
      ...state,
      users: state.users.sort((a, b) => a.tea - b.tea),
      tig_order: "asc",
    });
  }

  function sortTigPDown() {
    setState({
      ...state,
      users: state.users.sort((a, b) => b.tea - a.tea),
      tig_order: "desc",
    });
  }

  function sortTigCUp() {
    setState({
      ...state,
      users: state.users.sort((a, b) => a.tec - b.tec),
      tig_order: "asc",
    });
  }

  function sortTigCDown() {
    setState({
      ...state,
      users: state.users.sort((a, b) => b.tec - a.tec),
      tig_order: "desc",
    });
  }

  // function nbrPage(perPage, totalRows) {
  //   perPage = parseFloat(perPage);
  //   totalRows = parseFloat(totalRows);
  //   let totalPages = totalRows / perPage;

  //   if (
  //     parseInt(totalPages) === 0 ||
  //     parseInt(totalPages) < parseFloat(totalPages)
  //     ) {
  //     setPages(parseInt(totalPages) + 1);
  //   } else {
  //     setPages(totalPages);
  //   }
  // }

  // async function getUsersByPage(page) {
  //   if (page > 0 && page <= pages) {
  //     console.log(page)
  //     await getUsers(state.query, page);
  //   }
  // }

  return (
    <div>
      <Header />
      <div className="mx-2 md:mx-14 mt-6 space-y-2">
        <div>
          <div className="font-source text-3xl font-bold text-blue-600">
            UTILISATEURS
          </div>
          <div className="flex space-x-4">
            <input
              type="text"
              name="query"
              onChange={newHandleQueryChange}
              className="w-full md:w-1/4 my-5 p-2 border-2 border-blue-300 rounded-lg"
            />
            <div className="flex items-center">
              <button
                onClick={() => sortUp()}
                data-tip="Sort ascending"
                data-for="sort_ascending"
              >
                {" "}
                Name <i className="fa fa-sort-up"></i>
                <ReactTooltip
                  id="sort_ascending"
                  effect="solid"
                  data-multiline="true"
                />
              </button>
              <button
                onClick={() => sortDown()}
                data-tip="Sort descending"
                data-for="sort_descending"
              >
                <i className="fa fa-sort-down"></i>
                <ReactTooltip
                  id="sort_descending"
                  effect="solid"
                  data-multiline="true"
                />
              </button>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => sortTigPUp()}
                data-tip="Sort ascending"
                data-for="sort_ascending"
              >
                {" "}
                TEA <i className="fa fa-sort-up"></i>
                <ReactTooltip
                  id="sort_ascending"
                  effect="solid"
                  data-multiline="true"
                />
              </button>
              <button
                onClick={() => sortTigPDown()}
                data-tip="Sort descending"
                data-for="sort_descending"
              >
                <i className="fa fa-sort-down"></i>
                <ReactTooltip
                  id="sort_descending"
                  effect="solid"
                  data-multiline="true"
                />
              </button>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => sortTigCUp()}
                data-tip="Sort ascending"
                data-for="sort_ascending"
              >
                {" "}
                TEC <i className="fa fa-sort-up"></i>
                <ReactTooltip
                  id="sort_ascending"
                  effect="solid"
                  data-multiline="true"
                />
              </button>
              <button
                onClick={() => sortTigCDown()}
                data-tip="Sort descending"
                data-for="sort_descending"
              >
                <i className="fa fa-sort-down"></i>
                <ReactTooltip
                  id="sort_descending"
                  effect="solid"
                  data-multiline="true"
                />
              </button>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => sortTigTUp()}
                data-tip="Sort ascending"
                data-for="sort_ascending"
              >
                {" "}
                TT
                <i className="fa fa-sort-up"></i>
                <ReactTooltip
                  id="sort_ascending"
                  effect="solid"
                  data-multiline="true"
                />
              </button>
              <button
                onClick={() => sortTigTDown()}
                data-tip="Sort descending"
                data-for="sort_descending"
              >
                <i className="fa fa-sort-down"></i>
                <ReactTooltip
                  id="sort_descending"
                  effect="solid"
                  data-multiline="true"
                />
              </button>
            </div>
            <div className="ml-96">
              <div className="mt-4 ml-96">
                <Button
                  onClick={() => {
                    tableToCSV();
                  }}
                  variant="contained"
                  className="space-x-2"
                >
                  <i className="fa-light fa-download"></i>
                  <span>Exporter en .csv</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto relative">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-blue-100">
              <tr>
                <th scope="col" id="thh" className="py-3 px-6">
                  Name
                </th>
                <th scope="col" id="thh" className="py-3 px-6">
                  Email
                </th>
                <th scope="col" id="thh" className="py-3 px-6">
                  Type
                </th>
                <th scope="col" id="thh" className="py-3 px-6">
                  Created at
                </th>
                <th scope="col" id="thh" className="py-3 px-6">
                  Updated at
                </th>
                <th scope="col" className="py-3 px-6">
                  Edit rights
                </th>
                <th scope="col" id="thh" className="py-3 px-6">
                  TEA
                </th>
                <th scope="col" id="thh" className="py-3 px-6">
                  TEC
                </th>
                <th scope="col" id="thh" className="py-3 px-6">
                  TT
                </th>
              </tr>
            </thead>
            <tbody>
              {state.users
                && state.users.length > 0 &&
                state.users.map((user, index) => (
                  <tr
                    className="bg-white border-b dark:bg-gray-700 dark:border-gray-700"
                    key={index}
                  >
                    <td id="tdd"
                      scope="row"
                      className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {user.name}
                    </td>
                    <td id="tdd" className="py-4 px-6">{user.email}</td>
                    <td id="tdd" className="py-4 px-6">{user.type.toUpperCase()}</td>
                    <td id="tdd" className="py-4 px-6">
                      {user.createdAt.split("T")[0] +
                        " at " +
                        user.createdAt.split("T")[1].split(".")[0]}
                    </td>
                    <td id="tdd" className="py-4 px-6">
                      {user.updatedAt.split("T")[0] +
                        " at " +
                        user.updatedAt.split("T")[1].split(".")[0]}
                    </td>
                    <td className="py-4 px-6">
                      {user.type === "student" ? (
                        <button
                          onClick={() => {staff(user._id) }}
                          className="text-green-400"
                        >
                          {" "}
                          SET STAFF{" "}
                        </button>
                      ) : user.type === "staff" ? (
                        <button
                          onClick={() => staff(user._id)}
                          className="text-red-400"
                        >
                          {" "}
                          REMOVE STAFF{" "}
                        </button>
                      ) : (
                        <i className="fa-solid fa-ban"></i>
                      )}
                    </td>
                    <td id="tdd" className="py-4 px-6"> {user.tea} </td>
                    <td id="tdd" className="py-4 px-6"> {user.tec} </td>
                    <td id="tdd" className="py-4 px-6"> {user.tt} </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        {/* <div>
          <span className="font-source font-semibold">
            Showing {state.users.length} items out of{" "}
            {store.getState().admin.totalRows} results found
          </span>
          <span className="font-source font-semibold">
            Page {state.page} / {pages}
          </span>

          {state.page > 1 && (
            <span className="font-source font-semibold">
              <button
                onClick={() => getUsersByPage(state.page - 1)}
                data-tip="Download all users list"
                data-for={"download"}
              >
                <i className="fa fa-chevron-circle-left"></i> Previous page
              </button>
            </span>
          )}

          {state.page < pages && (
            <span className="font-source font-semibold">
              <button
                onClick={() => {getUsersByPage(state.page + 1); setState({...state, page: state.page + 1})}}
                data-tip="Download all users list"
                data-for={"download"}
              >
                Next page <i className="fa fa-chevron-circle-right"></i>
              </button>
            </span>
          )}
        </div> */}
      </div>
      <Footer />
    </div>
  );
}

export { UsersLists };
