import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { getUserTigs } from "../store/actions/userActions";
import store from "../store";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Chip,
  Container,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import { Footer } from "../components/Footer";

const Tig = () => {
  const [status, setStatus] = useState("pending");
  const [tigs, setTigs] = useState([]);
  const [pending, setPending] = useState([]);
  const [inProgress, setInProgress] = useState([]);
  const [closed, setClosed] = useState([]);
  const [canceled, setCancel] = useState([]);
  const [e, setE] = useState("");
  const dispatch = useDispatch();
  // function gravity(status) {
  //   switch (status) {
  //     case "low":
  //       return "green";
  //     case "medium":
  //       return "orange";
  //     case "high":
  //       return "red";
  //     default:
  //       return "";
  //   }
  // }
  const { accounts } = useMsal();
  const mail = accounts[0] && accounts[0].username;
  const [currentPage, setCurrentPage] = useState(0);
  const PER_PAGE = 9;
  const Range = 5;
  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(tigs.length / PER_PAGE);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }


  useEffect(() => {

      countTigs();
      getTigs();
    
  }, [status, currentPage]);

  async function getTigs() {
    await dispatch(getUserTigs(mail, status)).then(() => {
      setTigs(store.getState().user.tigs.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt)));
    });
  }

  async function countTigs() {
    if (mail) {
      try {
        await dispatch(getUserTigs(mail, "pending")).then(() => {
          setPending(store.getState().user.tigs.length);
        });
        await dispatch(getUserTigs(mail, "in progress")).then(() => {
          setInProgress(store.getState().user.tigs.length);
        });
        await dispatch(getUserTigs(mail, "closed")).then(() => {
          setClosed(store.getState().user.tigs.length);
        });
        await dispatch(getUserTigs(mail, "canceled")).then(() => {
          setCancel(store.getState().user.tigs.length);
        });
      } catch (e) {
        setE(e);
      }
    }
  }

  function handlePending() {
    setStatus("pending");
    setCurrentPage(0);
    setCurrentPage(0);
  }

  function handleInProgress() {
    setStatus("in progress");
    setCurrentPage(0);
  }

  function handleClose() {
    setStatus("closed");
    setCurrentPage(0);
  }

  function handleCancel() {
    setStatus("canceled");
    setCurrentPage(0);
  }

  return (
    <div>
      <div className="w-full flex items-center w-auto ml-2">
        <Container className="mt-4">
          <ButtonGroup aria-label="button group" id="sm">
            <Button
              style={{
                borderTopLeftRadius: "16px",
                borderBottomLeftRadius: "16px",
                backgroundColor: status === "pending" && "grey",
                color: status === "pending" && "white",
              }}
              onClick={() => {
                handlePending();
              }}
            >
              EN ATTENTE({pending}){" "}
            </Button>
            <Button
              style={{
                backgroundColor: status === "in progress" && "orange",
                color: status === "in progress" && "white",
              }}
              onClick={() => {
                handleInProgress();
              }}
            >
              EN COURS({inProgress})
            </Button>
            <Button
              style={{
                backgroundColor: status === "closed" && "green",
                color: status === "closed" && "white",
              }}
              onClick={() => {
                handleClose();
              }}
            >
              TERMINÉS({closed})
            </Button>
            <Button
              style={{
                borderTopRightRadius: "16px",
                borderBottomRightRadius: "16px",
                backgroundColor: status === "canceled" && "red",
                color: status === "canceled" && "white",
              }}
              onClick={() => {
                handleCancel();
              }}
            >
              ANNULÉS({canceled})
            </Button>
          </ButtonGroup>
        </Container>
      </div>
      <div>
        {tigs.length !== 0 ? (
          <div>
            <div className="flex flex-wrap justify-center gap-5 mt-8">
              {tigs.slice(offset, offset + PER_PAGE).map((tig) => {
                return (
                  <div
                    className="flex flex-wrap justify-start items-start w-[25rem] md:w-[25rem] bg-gray-200 bg-opacity-10 shadow p-3 gap-4 border"
                    key={tig._id}
                  >
                    <Accordion style={{ width: "100%", boxShadow: "unset" }}>
                      <AccordionSummary
                        style={{ padding: "0" }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <span className="text-xl font-semibold mr-2">
                          Assigné à :{" "}
                          {tig.user_mail.filter((item) => item !== "")
                            .length === 1 ? (
                            <span className="font-medium ml-3 text-lg">
                              Moi
                            </span>
                          ) : (
                            <span className="font-medium ml-3 text-lg">
                              Moi et{" "}
                              {tig.user_mail.filter((item) => item !== "")
                                .length - 1}{" "}
                              {tig.user_mail.filter((item) => item !== "")
                                .length -
                                1 >
                                1
                                ? "des étudiant.e.s"
                                : "étudiant.e"}
                            </span>
                          )}
                        </span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          {tig.user_mail.map((mail) => {
                            return (
                              mail !== "" && (
                                <Grid item xs={4} key={mail}>
                                  <Chip
                                    label={mail.split("@")[0]}
                                    className="mb-1"
                                  />
                                </Grid>
                              )
                            );
                          })}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <div className="text-black text-lg">
                      <span className="text-xl font-semibold mr-2">
                        Motif :
                      </span>{" "}
                      {tig.reason}
                    </div>
                    {/* <div className="text-black text-lg">
                    <span className="text-xl font-semibold mr-2">Gravité:</span>{" "}
                    <Chip
                      style={{ background: gravity(tig.gravity), color: "white" }}
                      label={tig.gravity}
                    />
                  </div> */}
                    <div className="text-black text-lg">
                      <span className="text-xl font-semibold mr-2">
                        Tigé(e) par :
                      </span>{" "}
                      <Chip label={tig.created_by} />
                    </div>
                    {tig.task ? (
                      <div className="p-4 bg-blue-600 w-full">
                        <span className="text-xl text-white font-semibold mr-2">
                          Tâche :
                        </span>
                        <span className="ml-1 text-white">{tig.task}</span>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {tig.status !== "pending" && (
                      <div className="text-black text-lg">
                        <span className="text-xl font-semibold mr-2">
                          Date de réalisation :
                        </span>
                        {moment(tig.due_date).format("ll à HH:mm")}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex flex-wrap justify-center gap-10 mt-8">
              <ReactPaginate
                className="flex justify-center space-x-4"
                breakLabel={"..."}
                pageRangeDisplayed={Range}
                forcePage={currentPage}
                previousLabel={"← Previous"}
                nextLabel={"Next →"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"text-blue-900 underline"}
              />
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export { Tig };
