import { React, useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { LogoutButton } from "./LogoutButton";
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { getUser } from "../store/actions/adminUsersActions";
import { register } from "../store/actions/authActions";
import store from "../store";
import { Badge } from "@mui/material";
import useAuthentication from "hooks/useAuthentication";

export const Header = (props) => {
  const [navbar, setNavbar] = useState(false);
  // const [role, setRole] = useState("");
  const navigate = useNavigate();
  // const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  // const name = accounts[0] && accounts[0].name;
  // const mail = accounts[0] && accounts[0].username;

  const { isAuthenticated, role, name, mail } = useAuthentication();


  // useEffect(() => {
  //   if (isAuthenticated) {
  //     dispatch(getUser(mail))
  //       .then(() => {
  //         setRole(store.getState().admin.otherUser.type);
  //       })
  //       .catch(() => {
  //         dispatch(register(name, mail))
  //           .then(() => {
  //             navigate("/");
  //           })
  //           .catch(() => {
  //             navigate("/");
  //           });
  //       });
  //   }
  // }, [isAuthenticated, dispatch, name, mail, navigate]);

  // useEffect(() => {
  //   let isSubscribed = true;
  //   if (!isAuthenticated) {
  //     navigate("/");
  //   }
  //   return () => (isSubscribed = false);
  // }, [isAuthenticated, navigate]);

  return (
    <>
      <div>
        <nav className="w-full bg-blue-800 shadow">
          <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
            <div>
              <div className="flex items-center justify-between py-3 md:py-5 md:block">
                {role === "admin" ? (
                  <a href="/dashboard">
                    <img src="/images/tig.svg" alt="Logo" />
                  </a>
                ) : (
                  <a href="/">
                    <img src="/images/tig.svg" alt="Logo" />
                  </a>
                )}
                <div className="md:hidden">
                  <button
                    className="p-2 text-gray-200 rounded-md outline-none focus:border-gray-400 focus:border"
                    onClick={() => setNavbar(!navbar)}
                  >
                    {navbar ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div
                className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                  navbar ? "block" : "hidden"
                }`}
              >
                {role === "staff" ? (
                  <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0" id="menu">
                    <li className="text-white font-semibold">
                      <NavLink className={({ isActive }) => isActive ? "border-b-2" : null} to='/'>MENTIONS</NavLink>
                    </li>
                    <li className="text-white font-semibold">
                      <NavLink className={({ isActive }) => isActive ? "border-b-2" : null} to='/tigs'>TIGS</NavLink>
                    </li>
                    <li className="flex items-center text-2xl text-gray-200 space-x-2 md:h-10 md:pl-12">
                      <div className="text-lg text-gray-200 mr-8">
                        <Badge badgeContent={role} color="primary">
                          <div className="text-lg font-semibold text-gray-200 md:pb-1">
                            {mail}
                          </div>
                        </Badge>
                      </div>
                      <LogoutButton />
                    </li>
                  </ul>
                ) : role === "admin" ? (
                  <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0" id="menu">
                    <li className="text-white font-semibold">
                      <NavLink className={({ isActive }) => isActive ? "border-b-2" : null} to='/dashboard'>DASHBOARD</NavLink>
                    </li>
                    <li className="text-white font-semibold">
                      <NavLink className={({ isActive }) => isActive ? "border-b-2" : null} to='/tigs'>TIGS</NavLink>
                    </li>
                    <li className="text-white font-semibold">
                      <NavLink className={({ isActive }) => isActive ? "border-b-2" : null} to='/users'>UTILISATEURS</NavLink>
                    </li>
                    <li className="flex items-center text-2xl text-gray-200 space-x-2 md:h-10 md:pl-12">
                      <div className="text-lg text-gray-200 mr-8">
                        <Badge badgeContent={role} color="primary">
                          <div className="text-lg font-semibold text-gray-200 md:pb-1">
                            {mail}
                          </div>
                        </Badge>
                      </div>
                      <LogoutButton />
                    </li>
                  </ul>
                ) : (
                  <div className="flex items-center text-2xl text-gray-200 space-x-2 h-10">
                    <div className="flex items-center text-2xl text-gray-200 space-x-8 h-10">
                      <Badge badgeContent={role} color="primary">
                        <div className="text-lg font-semibold text-gray-200 md:pb-1">
                          {mail}
                        </div>
                      </Badge>
                      <LogoutButton />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="text-gray-200 text-2xl hidden md:"></div>
          </div>
        </nav>
      </div>
    </>
  );
};
