export const Footer = () => {
  return (
    <div className="relative bottom-0 right-3 flex justify-center">
      <div className="mb-3 mt-8">
        Copyright © 2022{" "}
        <a
          href="https://webforms.pipedrive.com/f/1HI6HkTUhzvso6V5ELl3HlxeYoCCSdK5IXQaffZbQjP517TCnBlReuCfbtzZ0fcJR"
          className="text-blue"
        >
          Pulse
        </a>
      </div>
    </div>
  );
};
