import React, { useEffect, useState, useReducer } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  getUser,
  searchUser,
  createTig,
  assignTask,
  closeTig,
  cancelTig,
  tByStatus,
} from "../../store/actions/adminUsersActions";
import { getAuthorTigs } from "../../store/actions/userActions";
import store from "../../store";
import { Header } from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { sendNotify } from "../../hooks/notify/notify";
import LoadingButton from "@mui/lab/LoadingButton";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveIcon from "@mui/icons-material/Save";
import { Footer } from "components/Footer";
import ReactPaginate from "react-paginate";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  Container,
  Grid,
  IconButton,
  Modal,
  TextField,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import useAuthentication from "hooks/useAuthentication";


const StaffTig = () => {
  const [toggleStud, setToggleStud] = useState(false);
  const [toggleTig, setToggleTig] = useState(false);
  const [toggleTask, setToggleTask] = useState(false);
  const [status, setStatus] = useState("pending");
  const [e, setE] = useState("");
  // const [role, setRole] = useState("");
  const [tigs, setTigs] = useState([]);
  const [pending, setPending] = useState([]);
  const [inProgress, setInProgress] = useState([]);
  const [closed, setClosed] = useState([]);
  const [canceled, setCancel] = useState([]);
  const [task, setTask] = useState("");
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [motive, setMotive] = useState("");
  const [error, setError] = useState("");
  const [gravity, setGravity] = useState("low");
  const [mailsEnter, setMailsEnter] = useState("");
  const [usersMails, setUsersMails] = useState([]);
  const [currentTig, setCurrentTig] = useState(null);
  const [validMail, setValidMails] = useState(false);
  const [loading, setLoading] = useState(false);
  // const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const [value, setValue] = useState(dayjs().format("YYYY-MM-DDTHH:mm:ss"));
  const [currentPage, setCurrentPage] = useState(0);
  const PER_PAGE = 9;
  const Range = 5;
  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(tigs.length / PER_PAGE);

  const { isAuthenticated, role, name, mail } = useAuthentication();


  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const handleChange = (newValue) => {
    setValue(newValue.$d.getTime());
  };

  // function gravities(status) {
  //   switch (status) {
  //     case "low":
  //       return "green";
  //     case "medium":
  //       return "orange";
  //     case "high":
  //       return "red";
  //     default:
  //       return "";
  //   }
  // }
  // const mail = accounts[0] && accounts[0].username;
  const navigate = useNavigate();

  // useEffect(() => {
  //   let isSubscribed = true;
  //   if (isAuthenticated) {
  //     dispatch(getUser(mail))
  //       .then(() => {
  //         if (store.getState().admin.otherUser.type === "student") {
  //           navigate("/");
  //         }
  //         setRole(store.getState().admin.otherUser.type);
  //       })
  //       .then(() => {
  //         countTigs(store.getState().admin.otherUser.type);
  //         getTigs(store.getState().admin.otherUser.type);
  //       })
  //       .catch(() => {
  //         navigate("/");
  //       });
  //   } else {
  //     navigate("/");
  //   }
  //   return () => (isSubscribed = false);
  // }, [isAuthenticated, status]);

  useEffect(() => {
    // if (!isAuthenticated) {
    //   navigate("/");
    // } else {

    if (store.getState().admin && store.getState().admin.otherUser) {
      if (store.getState().admin.otherUser.type === "student") {
        navigate("/");
      } else {
        countTigs(store.getState().admin.otherUser.type);
        getTigs(store.getState().admin.otherUser.type);
      }
    }
    // }

  }, [isAuthenticated, navigate, status]);

  function getTigs(r) {
    if (mail && status) {
      if (r === "staff") {
        try {
          dispatch(getAuthorTigs(mail, status)).then(() => {
            setTigs(
              store
                .getState()
                .user.author_tigs.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
            );
          });
        } catch (e) {
          setE(e);
        }
      }
      if (r === "admin") {
        try {
          dispatch(tByStatus(status)).then(() => {
            setTigs(
              store
                .getState()
                .admin.tigs.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
            );
          });
        } catch (e) {
          setE(e);
        }
      }
    }
  }

  function countTigs(r) {
    if (r === "staff" && mail) {
      try {
        dispatch(getAuthorTigs(mail, "pending")).then(() => {
          setPending(store.getState().user.author_tigs.length);
        });
        dispatch(getAuthorTigs(mail, "in progress")).then(() => {
          setInProgress(store.getState().user.author_tigs.length);
        });
        dispatch(getAuthorTigs(mail, "closed")).then(() => {
          setClosed(store.getState().user.author_tigs.length);
        });
        dispatch(getAuthorTigs(mail, "canceled")).then(() => {
          setCancel(store.getState().user.author_tigs.length);
        });
      } catch (e) {
        setE(e);
      }
    }

    if (r === "admin") {
      try {
        dispatch(tByStatus("pending")).then(() => {
          setPending(store.getState().admin.tigs.length);
        });
        dispatch(tByStatus("in progress")).then(() => {
          setInProgress(store.getState().admin.tigs.length);
        });
        dispatch(tByStatus("closed")).then(() => {
          setClosed(store.getState().admin.tigs.length);
        });
        dispatch(tByStatus("canceled")).then(() => {
          setCancel(store.getState().admin.tigs.length);
        });
      } catch (e) {
        setE(e);
      }
    }
  }

  function handlePending() {
    setStatus("pending");
    setCurrentPage(0);
  }

  function handleInProgress() {
    setStatus("in progress");
    setCurrentPage(0);
  }

  function handleClose() {
    setStatus("closed");
    setCurrentPage(0);
  }

  function handleCancel() {
    setStatus("canceled");
    setCurrentPage(0);
  }

  function handleToggleTig() {
    setToggleTig(true);
    setToggleStud(false);
  }

  function handleToggleTask() {
    setToggleTask(true);
    setUsersMails([]);
    resetToggleStud();
    resetToggleTig();
  }

  function getUsers(key) {
    dispatch(searchUser(key)).then(() => {
      const u = store.getState().admin.users.users;
      const filtered = u.filter((user) => user.email !== mail);
      if (usersMails.length !== 0) {
        filtered.forEach((user) => {
          const index = usersMails.indexOf(user.email);
          if (index !== -1) {
            filtered.splice(filtered.indexOf(user), 1);
          }
        });
        setUsers(filtered);
      }
      setUsers(filtered);
    });
  }

  function removeMail(mail) {
    const index = usersMails.indexOf(mail);
    if (index !== -1) {
      usersMails.splice(index, 1);
      document
        .querySelectorAll("input[type=checkbox]")
        .forEach((el) => (el.checked = false));
    }
    setUsersMails(usersMails);
    getUsers(search);
  }

  const handleUsersMails = (e) => {
    const mails = [...usersMails];
    if (e.target.checked) mails.push(e.target.value);
    else {
      let index = mails.indexOf(e.target.value);
      mails.splice(index, 1);
    }
    setUsersMails(mails);
  };

  const handleMotive = (e) => {
    setMotive(e.target.value);
  };

  const handleMailsEnter = (e) => {
    const mail = e.target.value;
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (regex.test(mail)) {
      setValidMails(true);
    }
    setMailsEnter(mail);
  };
  const handleKeyDown = (event) => {
    const mails = [...usersMails];
    if (event.key === "Enter" && validMail) {
      mails.push(mailsEnter);
      setMailsEnter("");
      setValidMails(false);
    }
    setUsersMails(mails);
  };

  // const handleGravity = (e) => {
  //   setGravity(e.target.value);
  // };

  const handleTask = (e) => {
    setTask(e.target.value);
  };

  const handleSearch = (e) => {
    if (e.target.value !== "") {
      getUsers(e.target.value);
      setSearch(e.target.value);
    } else {
      setSearch("");
      setUsers([]);
    }
  };

  function resetToggleStud() {
    setUsersMails([]);
    setSearch("");
    setUsers([]);
    setToggleStud(false);
  }

  function resetToggleTig() {
    setToggleTig(false);
    setLoading(false);
    setMotive("");
    setGravity("low");
    setUsersMails([]);
    setMailsEnter("");
    setError("");
    setUsers([]);
  }

  function resetToggleTask() {
    setToggleTask(false);
    setTask("");
  }

  async function newTig() {
    const mailsToArray = mailsEnter.split(" ");
    const occurrences = {};
    const err = [];
    if (usersMails.length !== 0) {
      usersMails.forEach((mail) => {
        mailsToArray.push(mail);
      });
      for (let i = 0, j = mailsToArray.length; i < j; i++) {
        occurrences[mailsToArray[i]] = (occurrences[mailsToArray[i]] || 0) + 1;
      }
      mailsToArray.forEach((mail) => {
        if (occurrences[mail] > 1) {
          err.push("Répétition d'adresses mails !");
        }
      });
    }
    if (motive === "") {
      setError("Veuillez renseigner un motif !");
    } else if (err.length !== 0) {
      setError("Répétition d'adresses mails !");
    } else {
      if (mailsToArray.length === 0) {
        setError("Aucun étudiant sélectionné !");
      } else {
        dispatch(createTig(motive, gravity, mailsToArray, mail, mail)).then(
          () => {
            // countTigs(role);
            sendNotify("success", "Ajout TIG !", "Nouveau TIG ajouté !");
            setStatus("pending");
            resetToggleTig();
          }
        );
      }
    }
  }

  async function tasked(tig_id) {
    if (task === "") {
      setError("Veuillez renseigner une tâche !");
    } else {
      dispatch(assignTask(tig_id, task, value)).then(() => {
        sendNotify("success", "Assignation de Tâche !", "Tâche assigneée !");
        setStatus("in progress");
        resetToggleTask();
      });
    }
  }

  async function cancel(tig_id) {
    dispatch(cancelTig(tig_id)).then(() => {
      sendNotify("success", "Annulation TIG !", "Tig annulé !");
      setStatus("canceled");
    });
  }

  async function close(tig_id) {
    dispatch(closeTig(tig_id)).then(() => {
      sendNotify("success", "Fermerture TIG !", "Tig fermé !");
      setStatus("closed");
    });
  }

  return (
    <div>
      <Header />
      <div className="">
        <div className="w-full flex items-center w-auto ml-2">
          <Container className="mt-4">
            <ButtonGroup aria-label="button group" className="mr-8" id="sm">
              <Button
                style={{
                  borderTopLeftRadius: "16px",
                  borderBottomLeftRadius: "16px",
                  backgroundColor: status === "pending" && "grey",
                  color: status === "pending" && "white",
                }}
                onClick={() => {
                  handlePending();
                }}
              >
                EN ATTENTE ({pending})
              </Button>
              <Button
                style={{
                  backgroundColor: status === "in progress" && "orange",
                  color: status === "in progress" && "white",
                }}
                onClick={() => {
                  handleInProgress();
                }}
              >
                EN COURS({inProgress})
              </Button>
              <Button
                style={{
                  backgroundColor: status === "closed" && "green",
                  color: status === "closed" && "white",
                }}
                onClick={() => {
                  handleClose();
                }}
              >
                TERMINÉS({closed})
              </Button>
              <Button
                style={{
                  borderTopRightRadius: "16px",
                  borderBottomRightRadius: "16px",
                  backgroundColor: status === "canceled" && "red",
                  color: status === "canceled" && "white",
                }}
                onClick={() => {
                  handleCancel();
                }}
              >
                ANNULÉS({canceled})
              </Button>
            </ButtonGroup>
            <div className="mt-4">
              <Button
                onClick={() => {
                  handleToggleTig();
                }}
                variant="contained"
                startIcon={<AddIcon />}
              >
                AJOUTER UN TIG
              </Button>
            </div>
          </Container>
          <Container className="mt-4">
            <Modal
              className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex"
              open={toggleStud}
            >
              <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
                {/* Modal content */}
                <div className="relative bg-white  rounded-lg shadow">
                  {/* Modal header */}
                  <div className="flex justify-between items-start p-4 rounded-t border-b border-gray-200">
                    <h3 className="text-xl font-semibold">
                      CHOIX D'ETUDIANT.S
                    </h3>
                    <IconButton
                      aria-label="close"
                      size="small"
                      onClick={() => resetToggleStud()}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  </div>
                  {/* Modal body */}
                  <div className="p-8 space-y-2">
                    <div className="flex flex-wrap space-x-2">
                      {usersMails.map((mail) => {
                        return (
                          <div
                            className="text-lg text-gray-300 flex flex-wrap items-center border-rounded-xl space-x-2 p-1"
                            key={mail}
                          >
                            <Chip
                              label={mail}
                              onDelete={() => removeMail(mail)}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div className="flex flex-line space-x-2 items-center">
                      <SearchIcon />
                      <input
                        onChange={handleSearch}
                        value={search}
                        className="appearance-none block w-full h-2/3 px-3 py-1.5 text-lg bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        type="text"
                        name="search_bar"
                        placeholder="Chercher un.e étudiant.e"
                        required
                      />
                    </div>
                  </div>
                  {/* Modal footer */}
                  <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                    <Button
                      variant={"contained"}
                      onClick={() => handleToggleTig()}
                      type="button"
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center"
                    >
                      SUIVANT
                    </Button>
                    <Button
                      onClick={() => resetToggleStud()}
                      data-modal-toggle="defaultModal"
                      type="button"
                      className="text-white bg-red-400 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-lg font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                    >
                      ANNULER
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Main modal tig */}
            <Modal
              className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex"
              open={toggleTig}
            >
              <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow">
                  {/* Modal header */}
                  <div className="flex justify-between items-start p-4 rounded-t border-b border-gray-200">
                    <h3 className="text-xl font-semibold">NOUVEAU TIG</h3>
                    <IconButton
                      aria-label="close"
                      size="small"
                      onClick={() => resetToggleTig()}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  </div>
                  {/* Modal body */}
                  <div className="p-4">
                    <div>
                      <div className="">
                        {usersMails.length > 0 && (
                          <span className="text-lg font-semibold">
                            Assigné à :
                          </span>
                        )}
                        {usersMails.map((mail) => {
                          return (
                            <Chip
                              label={mail}
                              key={mail}
                              onDelete={() => removeMail(mail)}
                              style={{ margin: "4px" }}
                            />
                          );
                        })}
                      </div>
                      {error !== "" ? (
                        <div className="flex justify-center w-full py-2 text-sm font-source text-red-400">
                          <p>{error}</p>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div className="space-y-4 mt-2">
                      <div>
                        <div className="flex flex-line space-x-2 items-center">
                          <input
                            onChange={handleSearch}
                            value={search}
                            className="appearance-none block w-full h-2/3 px-3 py-1.5 text-lg bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            type="text"
                            name="search_bar"
                            placeholder="Chercher un.e étudiant.e"
                            required
                          />
                        </div>
                        {users.map((user) => {
                          return (
                            <div
                              className="form-check space-x-1"
                              key={user._id}
                            >
                              <Checkbox
                                checked={usersMails.includes(user.email)}
                                value={user.email}
                                onChange={handleUsersMails}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                              <label
                                className="form-check-label inline-block text-lg"
                                htmlFor="user"
                              >
                                {user.name}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex flex-line space-x-2 items-center mb-2">
                        <input
                          onChange={handleMailsEnter}
                          onKeyDown={handleKeyDown}
                          value={mailsEnter}
                          className="appearance-none block w-full px-3 py-1.5 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          type="text"
                          name="students_mails"
                          placeholder="Entrer l'email de l'étudiant.e si vous ne les trouvez pas"
                        />
                      </div>
                      <div className="w-full">
                        <textarea
                          onChange={handleMotive}
                          value={motive}
                          className="appearance-none block w-full px-3 py-1.5 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          type="text"
                          name="motive"
                          placeholder="Motif"
                          required
                        />
                      </div>
                      {/* <div>
                        <select
                          onChange={handleGravity}
                          value={gravity}
                          className="form-select appearance-none block w-full px-3 py-1.5 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        >
                          <option defaultValue="low">FAIBLE</option>
                          <option value="medium">MOYENNE</option>
                          <option value="high">FORTE</option>
                        </select>
                      </div> */}
                    </div>
                  </div>
                  {/* Modal footer */}
                  <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                    <LoadingButton
                      loading={loading}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant={"contained"}
                      onClick={() => {
                        newTig();
                      }}
                    >
                      CREER
                    </LoadingButton>
                    <Button onClick={() => resetToggleTig()}>ANNULER</Button>
                  </div>
                </div>
              </div>
            </Modal>
          </Container>
        </div>
        <div className="w-full">
          {tigs.length != 0 ? (
            <div className="w-full">
              <div className="w-full flex flex-wrap justify-center gap-5 mt-8">
                {tigs.slice(offset, offset + PER_PAGE).map((tig) => {
                  return (
                    <div
                      className="flex flex-wrap justify-start items-start w-[25rem] md:w-[25rem] bg-gray-200 bg-opacity-10 shadow p-3 gap-4 border"
                      key={tig._id}
                    >
                      <Accordion style={{ width: "100%", boxShadow: "unset" }}>
                        <AccordionSummary
                          style={{ padding: "0" }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <span className="text-xl font-semibold mr-2">
                            Assigné à :{" "}
                            <span className="font-medium ml-3 text-lg">
                              {
                                tig.user_mail.filter((item) => item !== "")
                                  .length
                              }{" "}
                              étudiant.e.s
                            </span>
                          </span>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            {tig.user_mail.map((mail) => {
                              return (
                                mail !== "" && (
                                  <Grid item xs={4} key={mail}>
                                    <Chip
                                      label={mail.split("@")[0]}
                                      className="mb-1"
                                    />
                                  </Grid>
                                )
                              );
                            })}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <div className="text-black text-lg">
                        <span className="text-xl font-semibold mr-2">
                          Motif :
                        </span>
                        {tig.reason}
                      </div>
                      {/* <div className="text-black text-lg">
                      <span className="text-xl font-semibold mr-2">Gravité:</span>
                      <Chip
                        style={{
                          background: gravities(tig.gravity),
                          color: "white",
                        }}
                        label={tig.gravity}
                      />
                    </div> */}
                      <div className="text-black text-lg">
                        <span className="text-xl font-semibold mr-2">
                          Tigé(e) par:
                        </span>
                        <Chip label={tig.created_by} />
                      </div>
                      {tig.task ? (
                        <div className="p-4 bg-blue-600 w-full">
                          <span className="text-xl text-white font-semibold mr-2">
                            Tâche :
                          </span>
                          <span className="ml-1 text-white">{tig.task}</span>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {tig.status !== "pending" && (
                        <div className="text-black text-lg">
                          <span className="text-xl font-semibold mr-2">
                            Date de réalisation :
                          </span>
                          {moment(tig.due_date).format("ll à HH:mm")}
                        </div>
                      )}
                      <div>
                        {tig.status === "pending" ? (
                          <div className="w-full flex justify-between">
                            {role === "admin" ? (
                              <div className="w-full flex justify-between">
                                <Button
                                  onClick={() => {
                                    handleToggleTask();
                                    setCurrentTig(tig._id);
                                  }}
                                  variant={"contained"}
                                >
                                  AJOUTER TÂCHE
                                </Button>
                                <Button onClick={() => cancel(tig._id)}>
                                  ANNULER TIG
                                </Button>
                              </div>
                            ) : (
                              <Button onClick={() => cancel(tig._id)}>
                                ANNULER TIG
                              </Button>
                            )}
                          </div>
                        ) : tig.status === "in progress" ? (
                          <div className="w-full flex justify-between">
                            {role === "admin" ? (
                              <div className="w-full flex justify-between">
                                <Button
                                  onClick={() => {
                                    close(tig._id);
                                  }}
                                  variant={"contained"}
                                  color="error"
                                >
                                  FERMER TIG
                                </Button>
                                <Button onClick={() => cancel(tig._id)}>
                                  ANNULER TIG
                                </Button>
                              </div>
                            ) : (
                              <Button onClick={() => cancel(tig._id)}>
                                ANNULER TIG
                              </Button>
                            )}
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                      {/* Main modal stud */}
                      <Modal
                        className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex"
                        open={toggleTask}
                      >
                        <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
                          {/* Modal content */}
                          <div className="relative bg-white rounded-lg shadow">
                            {/* Modal header */}
                            <div className="flex justify-between items-start p-4 rounded-t border-b border-gray-200">
                              <h3 className="text-xl font-semibold">
                                AJOUTER TÂCHE
                              </h3>
                              <IconButton
                                aria-label="close"
                                size="small"
                                onClick={() => resetToggleTask()}
                              />
                            </div>
                            {/* Modal body */}
                            <div className="p-4">
                              {error !== "" ? (
                                <div className="flex justify-center w-full py-2 text-sm font-source text-red-400">
                                  <p>{error}</p>
                                </div>
                              ) : (
                                <div></div>
                              )}
                              <div className="w-full">
                                <textarea
                                  onChange={handleTask}
                                  value={task}
                                  className="appearance-none block w-full px-3 py-1.5 mb-4 text-lg font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                  type="text"
                                  name="task"
                                  placeholder="Tâche"
                                  required
                                />
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  style={{ marginBottom: 20 }}
                                >
                                  <DateTimePicker
                                    label="Date de réalisation"
                                    value={value}
                                    onChange={handleChange}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                  />
                                </LocalizationProvider>
                              </div>
                            </div>
                            {/* Modal footer */}
                            <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                              <Button
                                onClick={() => {
                                  tasked(currentTig);
                                }}
                                variant={"contained"}
                              >
                                VALIDER
                              </Button>
                              <Button onClick={() => resetToggleTask()}>
                                ANNULER
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-wrap justify-center gap-10 mt-8">
                <ReactPaginate
                  className="flex justify-center space-x-4"
                  forcePage={currentPage}
                  breakLabel={"..."}
                  pageRangeDisplayed={Range}
                  previousLabel={"← Previous"}
                  nextLabel={"Next →"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  previousLinkClassName={"pagination__link"}
                  nextLinkClassName={"pagination__link"}
                  disabledClassName={"pagination__link--disabled"}
                  activeClassName={"text-blue-900 underline"}
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export { StaffTig };
